import * as React from "react";
import { MdHome, MdLanguage, MdPhone, MdSchool } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { SubmitErrorHandler, SubmitHandler } from "react-hook-form/dist/types/form";
import isMobilePhone from "validator/es/lib/isMobilePhone";
import firebase from "gatsby-plugin-firebase";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { PageProps } from "gatsby";
import axios from "axios";
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import ButtonSubmit from "../components/form/ButtonSubmit";
import StandardToaster from "../components/standardToaster";
import Field from "../components/form/field";
import useStaticAuthState from "../hooks/staticAuthState";

enum InstitutionType {
  school = "school",
  highschool = "highschool",
  university = "university",
}

type FormValues = {
  school: string;
  phoneNumber: string;
  webProof: string;
  type: InstitutionType;
};

function encode(data): string {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
}

const ApplyInstitution: React.FC<PageProps> = ({ location }) => {
  const [user] = useStaticAuthState(firebase.auth);
  const { register, handleSubmit } = useForm<FormValues>();
  const { t } = useTranslation(["translation", "applyInstitution"]);

  const onSubmit: SubmitHandler<FormValues> = (data, event) => {
    event.preventDefault();
    fetch(location.pathname, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "applyInstitution",
        ...data,
        email: user.email,
      }),
    })
      .then(() => toast.success(t("applyInstitution:formSubmitted")))
      .catch((error) => toast.error(error));
  };

  const onError: SubmitErrorHandler<FormValues> = (data) => {
    for (const f of Object.values(data)) {
      toast.error(f.message);
    }
  };

  return (
    <div className="flex flex-col h-full">
      <form name="applyInstitution" netlify netlify-honeypot="bot-field" data-netlify="true" hidden>
        <input type="hidden" name="form-name" value="applyInstitution" />
        <input type="text" name="email" />
        <input type="text" name="phoneNumber" />
        <input type="text" name="school" />
        <input type="text" name="webProof" />
        <select name="type">
          <option value="school">{t("applyInstitution:school")}</option>
          <option value="highschool">{t("applyInstitution:highschool")}</option>
          <option value="university">{t("applyInstitution:university")}</option>
        </select>
      </form>
      <Seo title={t("applyInstitution:title")} description={t("applyInstitution:description")} />
      <NavBar title={t("applyInstitution:title")} navButton={{ icon: MdHome, location: "/" }} />
      <StandardToaster />
      <div className="h-full w-full overflow-y-auto">
        <div className="flex flex-col min-h-full p-5 justify-center items-center">
          {user == null ? (
            <div className="flex flex-col justify-center items-center gap-4">
              <p className="text-white text-center text-2xl md:text-3xl lg:text-5xl">
                {t("applyInstitution:accountNeeded")}
              </p>
              <Link
                to={`/login?redirect=${location.pathname}`}
                className="cursor-pointer w-1/2 md:w-1/4 bg-orange text-white
                transition duration-500 ease-in-out transform hover:scale-105
                text-lg text-center py-2 rounded-lg"
              >
                {t("applyInstitution:goToLogin")}
              </Link>
            </div>
          ) : (
            <form
              data-netlify="true"
              name="applyInstitution"
              onSubmit={handleSubmit(onSubmit, onError)}
              className="w-full"
            >
              <div className="flex h-full items-center justify-center">
                <div className="flex flex-col flex-shrink w-2/3 sm:w-1/2 md:w-1/4 justify-center space-y-4">
                  <input type="hidden" name="form-name" value="applyInstitution" />
                  <label htmlFor="type" className="mx-1 text-lg text-white">
                    {t("applyInstitution:type")}
                  </label>
                  <div className="relative inline-block w-full text-black">
                    <select
                      className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline bg-white"
                      {...register("type", {
                        required: {
                          value: true,
                          message: t("applyInstitution:required", { name: t("applyInstitution:type") }),
                        },
                      })}
                    >
                      <option value="school">{t("applyInstitution:school")}</option>
                      <option value="highschool">{t("applyInstitution:highschool")}</option>
                      <option value="university">{t("applyInstitution:university")}</option>
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                        <path
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <Field
                    icon={MdSchool}
                    type="text"
                    placeholder={t("applyInstitution:yourInstitution")}
                    {...register("school", {
                      required: {
                        value: true,
                        message: t("applyInstitution:required", { name: t("applyInstitution:yourInstitution") }),
                      },
                    })}
                  />
                  <Field
                    icon={MdPhone}
                    type="text"
                    placeholder={t("applyInstitution:contactPhoneNumber")}
                    {...register("phoneNumber", {
                      required: {
                        value: true,
                        message: t("applyInstitution:required", { name: t("applyInstitution:contactPhoneNumber") }),
                      },
                      validate: (value) => isMobilePhone(value) || (t("applyInstitution:notMobilePhone") as string),
                    })}
                  />
                  <Field
                    icon={MdLanguage}
                    type="text"
                    placeholder={t("applyInstitution:webProof")}
                    {...register("webProof")}
                  />
                  <label htmlFor="webProof" className="mx-2 text-md text-white">
                    {t("applyInstitution:webProofDescription")}
                  </label>
                  <div className="flex w-full justify-center items-center">
                    <ButtonSubmit text={t("applyInstitution:apply")} />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyInstitution;
